import React, { useContext, useEffect, useRef, useState } from 'react';
import { GeoJSONFeature } from 'mapbox-gl';
import Mapbox, { Layer, Source } from 'react-map-gl';
import type { MapMouseEvent, MapRef, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import DrawControl from './DrawControl';
import { TBuildingPolygonGeojson, TBuildingPointGeojson, TMunicipalityAreasGeojson, TPostalAreasGeojson, TViewMode } from 'dippa-shared';
import { MainContext, RefContext, SelectedBuildingContext, SetContext, TSelectedPolygons } from '../Misc/Context';
import { DRAW_CONTROL_STYLES, getBuilding3dLayer, getBuildingLayer, getMunicipalityAreasLayer, getPostalAreasLayer, getSelectedBuildingLayer } from './layers';
import { fetchBuildings } from './fetchBuildings';
import { SERVER_URL } from '../Misc/consts';
import { axiosFetch, displayAxiosError } from '../Misc/commonFunctions';
import './Map.css';


const INITIAL_ZOOM = 12;
export const BUILDINGS_3D_ZOOM_BOUNDARY = 15;
export const BUILDINGS_ZOOM_BOUNDARY = 11.85;
export const MUNICIPALITIES_ZOOM_BOUNDARY = 9.99;


export const Map = ({ mapboxToken }: { mapboxToken: string }) => {
  const {
    buildingLayerFilters,
    postalAreasLayerFilters,
    viewMode,
    selectedPolygons,
    leftPanelCollapsed
  } = useContext(MainContext);
  const { selectedBuilding } = useContext(SelectedBuildingContext)
  const { drawControlRef } = useContext(RefContext);
  const {
    setEnergyCalculatorOpen,
    setSelectedPolygons,
    setPostalAreasLayerFilters,
    setViewMode,
    setSelectedBuilding,
    //setSelectedPolygonBuilding,
    setLoadingStatus
  } = useContext(SetContext);
  const mapRef = useRef<MapRef | null>(null);

  const [municipalityAreasGeojson, setMunicipalityAreasGeojson] = useState<TMunicipalityAreasGeojson>();
  const [postalAreasGeojson, setPostalAreasGeojson] = useState<TPostalAreasGeojson>();
  const [buildingsPointGeojson, setBuildingsPointGeojson] = useState<TBuildingPointGeojson>();
  const [buildingsPolygonGeojson, setBuildingsPolygonGeojson] = useState<TBuildingPolygonGeojson>();
  //const buildingFetchTimeout = useRef<NodeJS.Timeout>();
  const mouseClickCoords = useRef<{ lat: number, lng: number }>();
  const prevRoundBBox = useRef({ minLon: 0, minLat: 0, maxLon: 0, maxLat: 0 });
  const prevZoomLevel = useRef(INITIAL_ZOOM);
  const loadedRegions = useRef<{ [key: string]: { pointFeatures: TBuildingPointGeojson["features"], polygonFeatures: TBuildingPolygonGeojson["features"] } }>({});
  //const worker = useRef<Worker | null>(new Worker(new URL('./worker.ts', import.meta.url)));

  //console.log(new Date(), "Render")

  const clearPolygons = () => {
    setSelectedPolygons({});
    drawControlRef.current?.deleteAll();
    drawControlRef.current?.changeMode("simple_select");
  }


  const changeViewMode = (mode: TViewMode) => {
    setSelectedBuilding(null);
    //setSelectedPolygonBuilding(null);
    setEnergyCalculatorOpen(false);
    clearPolygons();
    if (mode === "buildings") {
      setPostalAreasLayerFilters([]);
    }
    setViewMode(mode)
  }


  const onMoveStart = React.useCallback(() => {
    if (viewMode === "buildings") {
      setLoadingStatus("loading")
    }
  }, [viewMode])


  const onBoundChange = React.useCallback(() => {
    if (viewMode !== "buildings") return;
    const sc = mapRef.current?.getBounds();
    if (!sc) return;
    const minLon = Math.floor(sc._sw.lng * 10) / 10;
    const minLat = Math.floor(sc._sw.lat * 20) / 20;
    const maxLon = Math.ceil(sc._ne.lng * 10) / 10;
    const maxLat = Math.ceil(sc._ne.lat * 20) / 20;
    if (
      minLon === prevRoundBBox.current.minLon &&
      minLat === prevRoundBBox.current.minLat &&
      maxLon === prevRoundBBox.current.maxLon &&
      maxLat === prevRoundBBox.current.maxLat
    ) {
      setLoadingStatus("")
      return;
    }

    prevRoundBBox.current.minLon = minLon;
    prevRoundBBox.current.minLat = minLat;
    prevRoundBBox.current.maxLon = maxLon;
    prevRoundBBox.current.maxLat = maxLat;
    fetchAndSetBuildingsGeojson(minLon, minLat, maxLon, maxLat)
  }, [viewMode])


  const onZoom = React.useCallback((e: ViewStateChangeEvent) => {
    const zoom = e?.viewState?.zoom;
    if (!zoom) return;

    if (zoom >= BUILDINGS_ZOOM_BOUNDARY
      && prevZoomLevel.current < BUILDINGS_ZOOM_BOUNDARY) {
      changeViewMode("buildings")
    }
    else if (zoom < BUILDINGS_ZOOM_BOUNDARY &&
      zoom >= MUNICIPALITIES_ZOOM_BOUNDARY &&
      (prevZoomLevel.current >= BUILDINGS_ZOOM_BOUNDARY ||
        prevZoomLevel.current < MUNICIPALITIES_ZOOM_BOUNDARY
      )) {
      setLoadingStatus("")
      changeViewMode("postalAreas")
    }
    else if (zoom < MUNICIPALITIES_ZOOM_BOUNDARY &&
      prevZoomLevel.current >= MUNICIPALITIES_ZOOM_BOUNDARY) {
      setLoadingStatus("")
      changeViewMode("municipalityAreas")
    }

    // const frac = (zoom - 12.5) / (15 - 12.5);
    // const pitch = Math.min(Math.max(0 * (1 - frac) + 70 * frac, 0), 70);
    // if (mapRef.current && mapRef.current.getPitch() > pitch) {
    //   mapRef.current.easeTo({
    //     zoom: zoom + 1,
    //     pitch: pitch,
    //     bearing: 80,
    //     duration: 500
    //   })
    // }
    prevZoomLevel.current = zoom;
  }, [])


  const onMouseOut = React.useCallback(() => {
    // Fixes a bug.
    // When mouse is over a feature and moves outside the map,
    // the polygon draw tool mouse style isn't applied if mouse cursor style isn't reset
    if (!mapRef.current) return
    mapRef.current.getCanvas().style.cursor = ''
  }, [])


  // TODO: Proper event type fails build compilation
  const onMouseMove = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    if (drawControlRef.current && drawControlRef.current.getMode() === "draw_polygon") return;

    const feature = getViewModeFeature(e.features)
    if (!feature) {
      mapRef.current.getCanvas().style.cursor = '';
      return
    };

    mapRef.current.getCanvas().style.cursor = 'pointer';
  }, [viewMode]);


  const onMouseDown = React.useCallback((e: MapMouseEvent) => {
    if (!mapRef.current) return;
    mouseClickCoords.current = mapRef.current.getCenter();
  }, []);


  // TODO: Proper event type fails build compilation
  const onMouseUp = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    if (drawControlRef.current && drawControlRef.current.getMode() === "draw_polygon") return;

    const { lat, lng } = mapRef.current.getCenter()
    if (mouseClickCoords.current?.lat !== lat || mouseClickCoords.current?.lng !== lng) {
      // Map was moved when mouse was down
      return
    }

    const feature = parseFeature(e.features)
    if (!feature) {
      // Empty area was clicked and map wasn't moved
      setSelectedBuilding(null);
      //setSelectedPolygonBuilding(null);
      setEnergyCalculatorOpen(false);
      if (viewMode !== "buildings") {
        clearPolygons();
      }
      return
    }

    if (viewMode === "buildings") {
      // @ts-ignore
      setSelectedBuilding(feature);
      // @ts-ignore
      //setSelectedPolygonBuilding(building3dFeature);
      //clearPolygons();
      return
    }

    const coordinates = feature.geometry?.coordinates;
    if (!coordinates) return;

    drawControlRef.current?.add({
      // @ts-ignore
      geometry: feature.geometry,
      id: "postal-area-selection",
      properties: {},
      type: "Feature"
    })
    setSelectedPolygons({
      "postal-area-selection": {
        // @ts-ignore
        geometry: feature.geometry,
        id: "postal-area-selection",
        properties: {},
        type: "Feature"
      }
    });
  }, [viewMode, municipalityAreasGeojson, postalAreasGeojson, buildingsPointGeojson, buildingsPolygonGeojson])


  const getViewModeFeature = (features: Array<GeoJSONFeature> | undefined) => {
    if (!features) return;
    switch (viewMode) {
      case "postalAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-postal-areas") {
            return feature;
          }
        }
        return;
      case "municipalityAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-municipality-areas") {
            return feature;
          }
        }
        return;
      case "buildings":
        for (const feature of features) {
          if (mapRef.current!.getZoom() > BUILDINGS_3D_ZOOM_BOUNDARY) {
            if (feature.layer?.source === "geojson-3d-buildings") {
              return feature;
            }
          }
          if (feature.layer?.source === "geojson-buildings") {
            return feature;
          }
        }
    }
  }


  const parseFeature = (features: Array<GeoJSONFeature> | undefined) => {
    const feature = getViewModeFeature(features)
    if (!feature) return;
    // NOTE: Mapbox can sometimes split polygons into smaller ones
    // or lose coordinate accuracy.
    // Original feature has to be queried from geojsons
    switch (viewMode) {
      case "buildings":
        if (!feature?.properties?.rtunnus || !buildingsPointGeojson) return;
        return buildingsPointGeojson.features.find(
          // @ts-ignore
          f => f.properties.rtunnus === feature.properties.rtunnus
        )
      case "postalAreas":
        if (!feature?.properties?.postCode || !postalAreasGeojson) return
        return postalAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.postCode === feature.properties.postCode
        );
      case "municipalityAreas":
        if (!feature?.properties?.finName || !municipalityAreasGeojson) return
        return municipalityAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.finName === feature.properties.finName
        );
    }
  }


  const building3dLayer = React.useMemo(() => (
    getBuilding3dLayer(buildingLayerFilters, selectedBuilding)
  ), [buildingLayerFilters, selectedBuilding])


  const buildingLayer = React.useMemo(() => (
    getBuildingLayer(buildingLayerFilters, viewMode)
  ), [buildingLayerFilters, viewMode])


  const selectedBuildingLayer = React.useMemo(() => (
    getSelectedBuildingLayer(buildingLayerFilters, viewMode)
  ), [buildingLayerFilters, viewMode])


  const postalAreasLayer = React.useMemo(() => (
    getPostalAreasLayer(postalAreasLayerFilters, viewMode)
  ), [postalAreasLayerFilters, selectedPolygons, viewMode])


  const municipalityAreasLayer = React.useMemo(() => (
    getMunicipalityAreasLayer([], viewMode)
  ), [selectedPolygons, viewMode])


  const onPolygonUpdate = React.useCallback((e: { features: TSelectedPolygons[] }, action?: string) => {
    // Is called when polygon drawing is finished or modifying is finished
    setSelectedPolygons(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);


  const drawControlMemo = React.useMemo(() => (
    <DrawControl
      ref={drawControlRef}
      displayControlsDefault={false}
      onCreate={onPolygonUpdate}
      onUpdate={onPolygonUpdate}
      styles={DRAW_CONTROL_STYLES}
    />
  ), [])


  const fetchAndSetBuildingsGeojson = async (
    minLon: number,
    minLat: number,
    maxLon: number,
    maxLat: number
  ) => {
    try {
      // if (worker.current) {
      //   worker.current.terminate();
      //   worker.current = null;
      // }
      const { gjsonPoints, gjsonPolygons } = await fetchBuildings({ minLon, minLat, maxLon, maxLat, loadedRegions });
      setBuildingsPointGeojson(gjsonPoints);
      setBuildingsPolygonGeojson(gjsonPolygons);
      setTimeout(() => {
        setLoadingStatus("");
      }, 600)
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }

    //clearTimeout(buildingFetchTimeout.current);
    // buildingFetchTimeout.current = setTimeout(async () => {

    // }, buildingFetchTimeout.current ? 400 : 0)
  }


  const fetchAndSetPostalAreasGeojson = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/postal-areas.geojson`)
      setPostalAreasGeojson(data);
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }


  const fetchAndSetMunicipalityAreasGeojson = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/municipality-areas.geojson`)
      setMunicipalityAreasGeojson(data);
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }


  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setSelectedBuilding(null);
      //setSelectedPolygonBuilding(null);
      setEnergyCalculatorOpen(false);
      clearPolygons();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 400)
    document.addEventListener('keydown', handleKeyPress);
    fetchAndSetPostalAreasGeojson();
    fetchAndSetMunicipalityAreasGeojson();
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      // worker.current?.terminate();
      // worker.current = null;
    };
  }, [])


  useEffect(() => {
    mapRef.current?.resize();
    if (viewMode === "buildings") {
      onBoundChange();
    }
  }, [leftPanelCollapsed, viewMode])


  return (
    <Mapbox
      ref={mapRef}
      mapboxAccessToken={mapboxToken}
      initialViewState={{
        latitude: 60.18,
        longitude: 24.94,
        zoom: INITIAL_ZOOM
      }}
      pitchWithRotate={true}
      dragRotate={true}
      showCompass={true}
      maxPitch={70}
      // @ts-ignore
      projection={"globe"}
      style={{ flex: 1, backgroundColor: "#000000" }}
      mapStyle={"mapbox://styles/ottoro/clxx3h6m1010301qr0kz18hig"}
      interactiveLayerIds={[buildingLayer.id!, building3dLayer[1].id!, postalAreasLayer[0].id!, municipalityAreasLayer[0].id!]}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOut={onMouseOut}
      onZoom={onZoom}
      //onMove={onBoundChange}
      onMoveStart={onMoveStart}
      onMoveEnd={onBoundChange}
      onLoad={onBoundChange}
    >
      {postalAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-postal-areas'
          data={postalAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {postalAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {municipalityAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-municipality-areas'
          // @ts-ignore
          data={municipalityAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {municipalityAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {buildingsPolygonGeojson ? (
        <Source
          type="geojson"
          id='geojson-3d-buildings'
          data={buildingsPolygonGeojson}
          cluster={false}
        >
          {building3dLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {buildingsPointGeojson ? (
        <Source
          type="geojson"
          id='geojson-buildings'
          data={buildingsPointGeojson}
          cluster={false}
          buffer={64}
        >
          <Layer {...buildingLayer} />
        </Source>
      ) : null}

      {selectedBuilding ? (
        <Source
          type="geojson"
          id='geojson-selected-building'
          data={selectedBuilding}
          cluster={false}
        >
          {selectedBuildingLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {drawControlMemo}
      {/* <NavigationControl
        position="bottom-right"
      /> */}
      <img
        src="sitowise_logo_valkoinen.png"
        alt="Sitowise logo"
        style={{
          pointerEvents: "none",
          width: 63,
          position: "absolute",
          left: 110,
          bottom: 12
        }} />
    </Mapbox>
  );
};
