import { useContext } from "react"
import { LoadingStatusContext } from "./Context"
import { CircularProgress } from "@mui/material"
import "./LoadingStatus.css"
import { COLORS } from "./consts"


export const LoadingStatus = () => {
  const { loadingStatus } = useContext(LoadingStatusContext)

  if (!loadingStatus || loadingStatus === "loading") {
    return (
      <div className={loadingStatus ? "circular-progress" : "circular-progress-transparent"}>
        <CircularProgress
          size={40}
          thickness={5.5}
          sx={{ color: COLORS.sitowise.puisto }}
        />
      </div>
    )
  }

  return (
    <div
      className={loadingStatus ? "circular-progress" : "circular-progress-transparent"}
      style={{ borderColor: COLORS.sitowise.graniitti, borderStyle: "solid", borderWidth: 2 }}
    >
      <p style={{ color: COLORS.sitowise.graniitti, fontSize: 16 }}>
        {loadingStatus}
      </p>
    </div>
  )
}