export const RAKENNUSLUOKITUS_CHECKBOXES = [
  {
    id: "01", label: 'Asuinrakennukset', children: [
      {
        id: "011", label: 'Pientalot', children: [
          { id: "0110" },
          { id: "0111" },
          { id: "0112" }
        ]
      },
      {
        id: "012", label: 'Kerrostalot', children: [
          { id: "0120" },
          { id: "0121" }
        ]
      },
      {
        id: "013", label: "Asuntolarakennukset", children: [
          { id: "0130" }
        ]
      },
      {
        id: "014", label: "Erityisryhmien asuinrakennukset", children: [
          { id: "0140" }
        ]
      }
    ],
  },
  {
    id: "02", label: "Vapaa-ajan asuinrakennukset", children: [
      {
        id: "021", label: "Vapaa-ajan asuinrakennukset", children: [
          { id: "0210" },
          { id: "0211" }
        ]
      },
    ]
  },
  {
    id: "03", label: "Liikerakennukset", children: [
      {
        id: "031", label: "Myymälärakennukset", children: [
          { id: "0310" },
          { id: "0311" },
          { id: "0319" },
        ]
      },
      {
        id: "032", label: "Majoitusliikerakennukset", children: [
          { id: "0320" },
          { id: "0321" },
          { id: "0322" },
          { id: "0329" },
        ]
      },
      {
        id: "033", label: "Ravintolarakennukset ja vastaavat liikerakennukset", children: [
          { id: "0330" }
        ]
      }
    ]
  },
  {
    id: "04", label: "Toimistorakennukset", children: [
      {
        id: "040", label: "Toimistorakennukset", children: [
          { id: "0400" }
        ]
      }
    ]
  },
  {
    id: "05", label: "Liikenteen rakennukset", children: [
      {
        id: "051", label: "Liikenne- ja kuljetusalan rakennukset", children: [
          { id: "0510" },
          { id: "0511" },
          { id: "0512" },
          { id: "0513" },
          { id: "0514" }
        ]
      },
      {
        id: "052", label: "Tieto- ja viestintätekniikan rakennukset", children: [
          { id: "0520" },
          { id: "0521" }
        ]
      },
      {
        id: "059", label: "Muut liikenteen rakennukset", children: [
          { id: "0590" }
        ]
      }
    ]
  },
  {
    id: "06", label: "Hoitoalan rakennukset", children: [
      {
        id: "061", label: "Terveydenhuoltorakennukset", children: [
          { id: "0610" },
          { id: "0611" },
          { id: "0612" },
          { id: "0613" },
          { id: "0614" },
          { id: "0619" },
        ]
      },
      {
        id: "062", label: "Sosiaalipalvelurakennukset", children: [
          { id: "0620" },
          { id: "0621" },
        ]
      },
      {
        id: "063", label: "Vankilarakennukset", children: [
          { id: "0630" }
        ]
      }
    ]
  },
  {
    id: "07", label: "Kokoontumisrakennukset", children: [
      {
        id: "071", label: "Kulttuurirakennukset", children: [
          { id: "0710" },
          { id: "0711" },
          { id: "0712" },
          { id: "0713" },
          { id: "0714" },
        ]
      },
      {
        id: "072", label: "Seura- ja kerhorakennukset", children: [
          { id: "0720" }
        ]
      },
      {
        id: "073", label: "Uskonnollisten yhteisöjen rakennukset", children: [
          { id: "0730" },
          { id: "0731" },
          { id: "0739" },
        ]
      },
      {
        id: "074", label: "Urheilu- ja liikuntarakennukset", children: [
          { id: "0740" },
          { id: "0741" },
          { id: "0742" },
          { id: "0743" },
          { id: "0744" },
          { id: "0749" },
        ]
      },
      {
        id: "079", label: "Muut kokooontumisrakennukset", children: [
          { id: "0790" }
        ]
      }
    ]
  },
  {
    id: "08", label: "Opetusrakennukset", children: [
      {
        id: "081", label: "Varhaiskasvatuksen rakennukset", children: [
          { id: "0810" }
        ]
      },
      {
        id: "082", label: "Yleissivistävien oppilaitosten rakennukset", children: [
          { id: "0820" }
        ]
      },
      {
        id: "083", label: "Ammatillisten oppilaitosten rakennukset", children: [
          { id: "0830" }
        ]
      },
      {
        id: "084", label: "Korkeakoulu- ja tutkimuslaitosrakennukset", children: [
          { id: "0840" },
          { id: "0841" }
        ]
      },
      {
        id: "089", label: "Muut opetusrakennukset", children: [
          { id: "0890" },
          { id: "0891" }
        ]
      }
    ]
  },
  {
    id: "09", label: "Teollisuuden ja kaivannaistoiminnan rakennukset", children: [
      {
        id: "091", label: "Teollisuuden tuotantorakennukset", children: [
          { id: "0910" },
          { id: "0911" },
          { id: "0912" },
          { id: "0919" },
        ]
      },
      {
        id: "092", label: "Teollisuus- ja pienteollisuustalot", children: [
          { id: "0920" }
        ]
      },
      {
        id: "093", label: "Kaivannaistoiminnan rakennukset", children: [
          { id: "0930" },
          { id: "0939" }
        ]
      }
    ]
  },
  {
    id: "10", label: "Energiahuoltorakennukset", children: [
      {
        id: "101", label: "Energiantuotantorakennukset", children: [
          { id: "1010" },
          { id: "1011" }
        ]
      },
      {
        id: "109", label: "Muut energianhuoltorakennukset", children: [
          { id: "1090" },
          { id: "1091" }
        ]
      }
    ]
  },
  {
    id: "11", label: "Yhdyskuntatekniikan rakennukset", children: [
      {
        id: "111", label: "Vesihuollon rakennukset", children: [
          { id: "1110" },
        ]
      },
      {
        id: "112", label: "Jätehuollon rakennukset", children: [
          { id: "1120" },
        ]
      },
      {
        id: "113", label: "Materiaalien kierrätyskeskukset", children: [
          { id: "1130" },
        ]
      }
    ]
  },
  {
    id: "12", label: "Varastorakennukset", children: [
      {
        id: "121", label: "Varastorakennukset", children: [
          { id: "1210" },
          { id: "1211" },
          { id: "1212" },
          { id: "1213" },
          { id: "1214" },
          { id: "1215" },
        ]
      },
      {
        id: "131", label: "Pelastustoimen rakennukset", children: [
          { id: "1310" },
          { id: "1311" },
          { id: "1319" }
        ]
      }
    ]
  },
  {
    id: "13", label: "Pelastustoimen rakennukset", children: [
      {
        id: "131", label: "Pelastustoimen rakennukset", children: [
          { id: "1310" },
          { id: "1311" },
          { id: "1319" }
        ]
      }
    ]
  },
  {
    id: "14", label: "Maatalousrakennukset ja eläinsuojat", children: [
      {
        id: "141", label: "Kotieläinrakennukset", children: [
          { id: "1410" },
          { id: "1411" },
          { id: "1412" },
          { id: "1413" },
          { id: "1414" },
          { id: "1415" },
          { id: "1416" },
          { id: "1419" },
        ]
      },
      {
        id: "149", label: "Muut maatalousrakennukset", children: [
          { id: "1490" },
          { id: "1491" },
          { id: "1492" },
          { id: "1493" },
          { id: "1499" },
        ]
      }
    ]
  },
  {
    id: "19", label: "Muut rakennukset", children: [
      {
        id: "191", label: "Muut rakennukset", children: [
          { id: "1910" },
          { id: "1911" },
          { id: "1912" },
          { id: "1919" }
        ]
      }
    ]
  },
  {
    id: "20", label: "Ei muunnettavissa", children: [
      { id: "013" },
      { id: "041" },
      { id: "131" },
      { id: "162" },
      { id: "611" },
      { id: "613" },
      { id: "699" },
      { id: "711" },
      { id: "712" },
      { id: "719" },
      { id: "811" },
      { id: "819" }
    ]
  },
  { id: null, label: "Tuntematon" }
] as const


export const LAMMONLAHDE_CHECKBOXES = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: null },
] as const


export const LAMMONJAKO_CHECKBOXES = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: null },
] as const


export const RAKENNUSAINE_CHECKBOXES = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: null },
] as const


export const PROPS_AVAILABLE_CHECKBOXES = [
  { id: 1 },
  { id: 2 }
] as const