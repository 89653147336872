import React, { useContext, useEffect, useState } from 'react';
import { Calculate, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, CircularProgress, Collapse, IconButton, ListItem, ThemeProvider } from '@mui/material';

import { SelectedBuildingContext, SetContext } from '../Misc/Context';
import { TApi } from 'dippa-shared';
import { COLORS, SERVER_URL } from '../Misc/consts';
import { axiosFetch, interpolateColor } from '../Misc/commonFunctions';
import { MUI_THEME_BUILDING_INFO } from '../Misc/muiThemes';
import './BuildingInfo.css';
import { InfoArr } from '../Misc/InfoArr';


const CollapsableCert = ({ cert }: { cert: TApi["building-info.json"]["response"]["certificates"][0] }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <ListItem sx={{ padding: 0 }}>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          {open ? (
            <ExpandLess sx={{ color: "#b8b8b8", fontSize: 30, padding: 0 }} />
          ) : (
            <ExpandMore sx={{ color: "#b8b8b8", fontSize: 30, padding: 0 }} />
          )}

          {cert.title}
        </IconButton>
      </ListItem>
      {/* @ts-ignore */}
      <Collapse in={open} timeout="auto" unmountOnExit timeout={160}>
        {cert.certificate.map(({ title, value }, index) => (
          <div
            key={index}
            style={{ marginTop: 8 }}>
            <p style={{ color: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.6) }}>
              {title}
            </p>
            {title === "Id" ? (
              <p style={{ color: "#ffffff", fontSize: 13 }}>
                <a
                  href={`https://energiatodistusrekisteri.fi/energiatodistus?id=${value}&versio=2018`}
                  target="_blank"
                >{value}</a>
              </p>
            ) : (
              <p style={{ color: "#ffffff", fontSize: 13 }}>
                {value}
              </p>
            )}

          </div>
        ))}
      </Collapse>
    </div >
  )
}


export const BuildingInfo = () => {
  const { selectedBuilding } = useContext(SelectedBuildingContext)
  const { setEnergyCalculatorOpen } = useContext(SetContext);
  const [buildingInfo, setBuildingInfo] = useState<TApi["building-info.json"]["response"] | undefined>()
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    if (!selectedBuilding) return
    const timeout = setTimeout(() => {
      setFetching(true)
    }, 100)

    axiosFetch(`${SERVER_URL}/building-info.json?rtunnus=${selectedBuilding.properties.rtunnus}`)
      .then(({ data }) => {
        setBuildingInfo(data)
        clearTimeout(timeout);
        setFetching(false)
      })
      .catch(e => {
        setBuildingInfo({ calculatorAvailable: false, main: [{ title: "Error", value: e.message }], certificates: [] })
        clearTimeout(timeout);
        setFetching(false)
      })
  }, [selectedBuilding])

  if (!buildingInfo) return null

  if (fetching) return (
    <div className={`building-info-container ${selectedBuilding ? "" : "building-info-container-transparent"}`}>
      <CircularProgress
        size={38}
        style={{ alignSelf: "center" }}
      //color={COLORS[colorPalette].eluokat["A"]} 
      />
    </div>
  )

  return (
    <ThemeProvider theme={MUI_THEME_BUILDING_INFO}>
      <div className={`building-info-container ${selectedBuilding ? "" : "building-info-container-transparent"}`}>
        {buildingInfo.calculatorAvailable ? (
          <Button
            variant="outlined"
            onClick={() => { setEnergyCalculatorOpen(true) }}
            startIcon={<Calculate />}
            sx={{ marginBottom: 1.5 }}
          >
            {"Avaa energialaskuri"}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={() => { setEnergyCalculatorOpen(true) }}
            startIcon={<Calculate />}
            sx={{ marginBottom: 1.5 }}
            disabled={true}
          >
            {"Energialaskuri ei saatavilla"}
          </Button>
        )}

        <InfoArr
          arr={buildingInfo.main}
        />

        {buildingInfo.certificates.length ? (
          buildingInfo.certificates.map((cert, index) => (
            <React.Fragment key={index}>
              <div className="horizontal-divider-wrapper">
                <div className="horizontal-divider" />
              </div>
              <CollapsableCert key={index} cert={cert} />
            </React.Fragment>
          ))
        ) : null}
      </div>
    </ThemeProvider>
  )
}