import { Box, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react";
import { EnergyCalculatorContext, SetContext } from "../Misc/Context";
import "./ParametersTab.css";
import { TCalcProps } from "dippa-shared";

export const ParametersTab = () => {
  const { energyCalcResponse } = useContext(EnergyCalculatorContext);
  const { setEnergyCalcProps } = useContext(SetContext);
  const [tempEnergyCalcProps, setTempEnergyCalcProps] = useState<TCalcProps>();

  useEffect(() => {
    if (!energyCalcResponse?.calcProps) return;
    setTempEnergyCalcProps(energyCalcResponse.calcProps)
  }, [energyCalcResponse])

  if (!tempEnergyCalcProps) return null

  return (
    <div className="parameters-tab">
      <Box component="form" onSubmit={() => { }} onInput={() => { }} noValidate>
        {Object.entries(tempEnergyCalcProps).map(([prop, value], index) => {
          if (typeof value !== "number" && typeof value !== "string" && prop !== "kayttotarkoitusluokka") return null; // TODO: Lämpöpumput, ikkunat yms
          return (
            <TextField
              key={index}
              required
              fullWidth
              id={prop}
              label={prop}
              name={prop}
              value={value}
              // @ts-ignore
              onChange={(e) => {
                const val = e.target.value;
                const valParsed = parseFloat(val);
                // @ts-ignore
                setTempEnergyCalcProps(prev => ({ ...prev, ...{ [prop]: val } }))
                if (!isNaN(valParsed) && val.substring(val.length - 1) !== ".") {
                  setEnergyCalcProps(prev => {
                    if (!prev) {
                      // @ts-ignore
                      return { ...tempEnergyCalcProps, ...{ [prop]: valParsed } }
                    }

                    return { ...prev, ...{ [prop]: valParsed } }
                  })
                }
              }}
              autoFocus
              sx={{ mb: 2.5 }}
            />
          )
        })}
      </Box>
    </div>
  )
}