import axios from "axios"
import { TSetState } from "./Context";


export const axiosFetch = async (url: string) => {
  const token = localStorage.getItem('token');
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res
}


export const axiosPost = async (url: string, data: object) => {
  const token = localStorage.getItem('token');
  const res = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res
}


const hexToRgb = (hex: string) => {
  // Remove the "#" symbol if present
  hex = hex.replace(/^#/, '');

  // If it's a shorthand hex (e.g., #03F), expand it to full form
  if (hex.length === 3) {
    hex = hex.split('').map(x => x + x).join('');
  }

  // Convert hex values to RGB
  const bigint = parseInt(hex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255
  };
}


export const displayAxiosError = (e: unknown, setLoadingStatus: TSetState<"loading" | string>) => {
  if (axios.isAxiosError(e)) {
    console.log("Error fetching building json", e.message);
    setLoadingStatus(`${e.message} ${e.response?.status ? `(${e.response?.status})` : ``}`);
    return;
  }

  // @ts-ignore
  setLoadingStatus(`Something went wrong: ${e}`);
}


const rgbToHex = (r: number, g: number, b: number) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}


export const interpolateColor = (color1: string, color2: string, ratio: number) => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Interpolate each component (r, g, b)
  const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * ratio);
  const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * ratio);
  const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * ratio);

  return rgbToHex(r, g, b);
}