import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Checkbox, FormControlLabel, Collapse, IconButton, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore, SyncAlt } from '@mui/icons-material';

import { COLORS } from '../Misc/consts';
import { ScatterChart, VerticalBarChart } from './Charts';
import { RefContext, MainContext, TSelectedPolygons, SetContext, TSetState } from '../Misc/Context';
import { TApi, TBuildingFilters, TBuildingProperties, TDropdownSelection, TUploadedFilters, formatBuildPropKey } from 'dippa-shared';
import { MUI_THEME_AREA_INFO } from '../Misc/muiThemes';
import { SERVER_URL } from '../Misc/consts';
import { axiosPost } from '../Misc/commonFunctions';
import { CustomDropdown } from './CustomDropdown';
import { DROPDOWN_VALS } from './dropdownVals';
import { InfoArr } from '../Misc/InfoArr';
import "./AreaInfo.css"


const StateSwitchButton = ({
  setState1,
  setState2,
  state2
}: {
  setState1: TSetState<TDropdownSelection>,
  setState2: TSetState<TDropdownSelection>,
  state2: TDropdownSelection
}) => {
  const handleSwap = React.useCallback(() => {
    setState1((prevState1) => {
      // Swap the states
      setState2(prevState1);
      return state2;
    });
  }, [state2]);

  return (
    <IconButton
      onClick={handleSwap}
    >
      <SyncAlt sx={{ color: COLORS.sitowise.graniitti, fontSize: 20, transform: "rotate(90deg)" }} />
    </IconButton>
  )
}


const getPostFilters = (filters: TBuildingFilters) => {
  // Sets are converted into arrays since json parse cannot parse them on the server.
  const postFilters: TUploadedFilters = {
    rangeFilters: filters.rangeFilters,
    valueFilters: {}
  }
  for (const key of Object.keys(filters.valueFilters)) {
    const set = filters.valueFilters[key as keyof TBuildingProperties];
    if (set) {
      postFilters.valueFilters[key as keyof TBuildingProperties] = Array.from(set)
    }
  }
  return postFilters;
}


const Collapsable = ({ title, children }: { title: string, children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem sx={{ padding: 0 }}>
        <IconButton
          onClick={() => setOpen(prev => !prev)}
        >
          {open ? (
            <ExpandLess sx={{ color: "#b8b8b8", fontSize: 30, padding: 0 }} />
          ) : (
            <ExpandMore sx={{ color: "#b8b8b8", fontSize: 30, padding: 0 }} />
          )}

          {title}
        </IconButton>
      </ListItem>
      {/* @ts-ignore */}
      <Collapse in={open} timeout="auto" unmountOnExit timeout={160}>
        {children}
      </Collapse>
    </>
  )
}


export const AreaInfo = React.memo(() => {
  const [areaInfo, setAreaInfo] = useState<TApi["area-analysis.json"]["response"]>([]);
  const [dataAnalysisKey, setDataAnalysisKey] = useState<TDropdownSelection>({ val: "vvuosi", accessKey: "mapProps" })
  const [scatterXkey, setScatterXkey] = useState<TDropdownSelection>({ val: "Lahtotiedot / Lammitetty-nettoala", accessKey: "certProps" })
  const [scatterYkey, setScatterYkey] = useState<TDropdownSelection>({ val: "korala", accessKey: "mapProps" })
  const [ignoreScatterZeroes, setIgnoreScatterZeroes] = useState(false)
  const { filtersRef } = useContext(RefContext);
  const { buildingLayerFilters, selectedPolygons, viewMode } = useContext(MainContext);
  const { setLoadingStatus } = useContext(SetContext);
  const timeout = useRef<NodeJS.Timeout>();
  const selectedPolygonValues: Array<TSelectedPolygons> = React.useMemo(() => (
    Object.values(selectedPolygons)
  ), [selectedPolygons]);
  const isVisible = !!selectedPolygonValues.length && !!areaInfo.length


  const fetchAndSetAreaInfo = async () => {
    try {
      setLoadingStatus("loading")
      const { data } = await axiosPost(`${SERVER_URL}/area-analysis.json`, {
        polygons: selectedPolygonValues.map(polygon => polygon.geometry),
        filters: getPostFilters(filtersRef.current),
        viewMode,
        dataAnalysisKey,
        scatterYkey,
        scatterXkey,
        ignoreScatterZeroes
      });
      setAreaInfo(data);
      setLoadingStatus("")
    }
    catch (e) {
      console.error("Error fetching area-analysis:", e)
    }
  }


  useEffect(() => {
    if (!selectedPolygonValues.length) {
      setAreaInfo([]);
      return
    };

    // A fetch is done when polygon or filter hasn't changed in more than 100 ms
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      fetchAndSetAreaInfo();
    }, 200)
  }, [selectedPolygonValues, buildingLayerFilters, viewMode, dataAnalysisKey, scatterYkey, scatterXkey, ignoreScatterZeroes])


  return (
    <div className={`area-info-panel ${isVisible ? "" : "area-info-panel-transparent"}`}>
      {areaInfo.map((area, index) => (
        <ThemeProvider theme={MUI_THEME_AREA_INFO} key={index}>
          <div className="area-info-header">
            <h1>{area.title}</h1>
          </div>

          <InfoArr arr={area.basicInfo} />

          {area.consumptionInfo ? (
            <div key={index} className="area-info-field">
              <div className="horizontal-divider-wrapper">
                <div className="horizontal-divider" />
              </div>
              <Collapsable
                title={area.consumptionInfo.title}
              >
                {/* <p>
                    {title}
                  </p> */}
                <InfoArr arr={area.consumptionInfo.info} />
              </Collapsable>
            </div>
          ) : null}

          {area.basicBarCharts ? (
            area.basicBarCharts.map(({ title, chart }, index) => (
              <div key={index} className="area-info-field">
                <div className="horizontal-divider-wrapper">
                  <div className="horizontal-divider" />
                </div>
                <Collapsable
                  title={title}
                >
                  {/* <p>
                    {title}
                  </p> */}
                  <VerticalBarChart
                    data={chart}
                    fillColors={chart[0].name === "A" && chart[1].name === "B" ? COLORS.eluokat : undefined}
                  />
                </Collapsable>
              </div>
            ))) : null}

          {area.basicDataAnalysis && (
            <div className="area-info-field">
              <div className="horizontal-divider-wrapper">
                <div className="horizontal-divider" />
              </div>
              <Collapsable
                title={area.basicDataAnalysis.title}
              >
                <div style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "column", width: "100%" }}>
                  <CustomDropdown
                    items={DROPDOWN_VALS}
                    selectedItem={dataAnalysisKey.val}
                    itemSetter={setDataAnalysisKey}
                    valFormatter={formatBuildPropKey}
                  />
                </div>

                <VerticalBarChart
                  data={area.basicDataAnalysis.chart}
                />

                <InfoArr arr={area.basicDataAnalysis.info} />

              </Collapsable>
            </div>
          )}
          {area.scatter && (
            <div className="area-info-field">
              <div className="horizontal-divider-wrapper">
                <div className="horizontal-divider" />
              </div>

              {/* <p>
                  {"Kahden tietokentän korrelaatio"}
                </p> */}
              <Collapsable
                title={area.scatter.title}
              >
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 10,
                  marginBottom: 16
                }}>
                  <div className='button-wrapper' style={{ gap: 10 }}>
                    <div className='button-wrapper'>
                      <CustomDropdown
                        items={DROPDOWN_VALS}
                        selectedItem={scatterXkey.val}
                        itemSetter={setScatterXkey}
                        valFormatter={formatBuildPropKey}
                        prefix='x: '
                      />
                    </div>

                    <div className="button-wrapper">
                      <CustomDropdown
                        items={DROPDOWN_VALS}
                        selectedItem={scatterYkey.val}
                        itemSetter={setScatterYkey}
                        valFormatter={formatBuildPropKey}
                        prefix='y: '
                      />
                    </div>
                  </div>

                  <StateSwitchButton
                    setState1={setScatterXkey}
                    setState2={setScatterYkey}
                    state2={scatterYkey}
                  />
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ignoreScatterZeroes}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIgnoreScatterZeroes(prev => !prev)
                      }}
                    />
                  }
                  sx={{ flex: 1, marginBottom: 0.75, marginTop: -0.5 }}
                  label={"Älä huomioi nollia"}
                />

                {area.scatter.points && area.scatter.lines && area.scatter.barCharts ? (
                  <>
                    <p>
                      {"Scatter"}
                    </p>
                    {/* @ts-ignore */}
                    <ScatterChart scatter={area.scatter} />

                    {area.scatter.barCharts.map(({ title, data }, index) => (
                      <React.Fragment key={index}>
                        <div style={{ height: 16 }} />
                        <p>
                          {title}
                        </p>
                        <VerticalBarChart
                          data={data}
                        />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <div style={{ backgroundColor: "#2f2f2f", borderRadius: 10, padding: 10 }}>
                    <p>
                      {"Huom! Valitut arvot eivät ole numeerisia. Kuvaajat ovat poissa käytöstä."}
                    </p>
                  </div>
                )}
                <InfoArr arr={area.scatter.info} />

              </Collapsable>
            </div>
          )}
        </ThemeProvider>
      ))}
    </div>
  );
});

//</ResponsiveContainer>
