import React, { useContext, useEffect, useRef } from "react";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";

import { TBuildingFilters, TBuildingProperties, formatRluok, formatLammonlahde, formatLammonjako, TRluok, formatRakennusaine } from "dippa-shared";
import { FiltersContext, RefContext, SetContext } from "../Misc/Context";
import { COLORS } from "../Misc/consts";
import { NestedCheckboxMenu } from "./NestedCheckbox";
import { LAMMONJAKO_CHECKBOXES, LAMMONLAHDE_CHECKBOXES, PROPS_AVAILABLE_CHECKBOXES, RAKENNUSAINE_CHECKBOXES, RAKENNUSLUOKITUS_CHECKBOXES } from "./checkboxVals";
import "./Filters.css";


const addRangeFilter = (
  filters: TBuildingFilters["rangeFilters"],
  key: keyof TBuildingProperties,
  newFilters: Array<any>
) => {
  const filter = filters?.[key];
  if (!filter) return;
  const { min, max } = filter;
  if (!min && !max) return;
  const arr: Array<any> = [
    "all",
    // When range filter is applied, all unknown values are filtered so that values within the range remain
    ["!=", ["get", key], null],
  ]
  if (min) {
    arr.push(
      [">=", ["get", key], min]
    )
  }
  if (max) {
    arr.push(
      ["<=", ["get", key], max]
    )
  }

  if (arr.length > 1) {
    newFilters.push(arr)
  }
}


const addValueFilter = (
  filters: TBuildingFilters["valueFilters"],
  key: keyof TBuildingProperties,
  newFilters: Array<any>
) => {
  const filter = filters?.[key];
  if (!filter) return;
  const arr: Array<any> = ["all"];
  for (const value of Array.from(filter)) {
    arr.push(["!=", ["get", key], value])
  }
  if (arr.length > 1) {
    newFilters.push(arr)
  }
}


const getTicks = (
  min: { value: number, label: string },
  max: { value: number, label: string },
  step: number
) => {
  const arr = []
  for (let i = min.value; i <= max.value; i += step) {
    if (i === min.value) {
      arr.push({ value: i, label: min.label })
    }
    else if (i === max.value) {
      arr.push({ value: i, label: max.label })
    }
    else {
      arr.push({ value: i, label: String(i) })
    }
  }

  return arr
}


const FilterCheckbox = ({
  filters,
  value,
  label,
  accessKey,
  setFilters,
  horizontal,
  color
}: {
  filters: TBuildingFilters,
  value: string | number,
  label?: string,
  accessKey: keyof TBuildingProperties,
  setFilters: React.Dispatch<React.SetStateAction<TBuildingFilters>>,
  horizontal?: boolean,
  color?: string
}) => {
  const { valueFilters } = filters;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!valueFilters?.[accessKey]?.has(value)}
          onChange={(_, val: boolean) => {
            const newSet = valueFilters[accessKey] || new Set()
            if (val) {
              newSet.delete(value)
            }
            else {
              newSet.add(value)
            }
            setFilters(prev => (
              {
                ...prev,
                ...{
                  valueFilters: {
                    ...prev.valueFilters,
                    [accessKey]: newSet
                  }
                }
              }
            ))
          }}
        // sx={{
        //   '&.Mui-checked': {
        //     color: COLORS.sitowise.kupari
        //     //color: color
        //   }
        // }}
        />
      }
      label={label ? label : value}
      style={{
        color: color ? color : undefined,
        flexDirection: horizontal ? "column" : "row"
      }}
    />
  )
}


const FilterSlider = ({
  filters,
  min,
  max,
  tickInterval,
  step,
  accessKey,
  setFilters
}: {
  filters: TBuildingFilters,
  min: { value: number, label: string },
  max: { value: number, label: string },
  tickInterval: number,
  step: number,
  accessKey: keyof TBuildingProperties,
  setFilters: React.Dispatch<React.SetStateAction<TBuildingFilters>>,

}) => {
  const { rangeFilters } = filters;
  const ticks = React.useMemo(() => getTicks(
    min,
    max,
    tickInterval
  ), [])
  const firstVal = rangeFilters[accessKey]?.min;
  const secondVal = rangeFilters[accessKey]?.max;

  const onChange = React.useCallback((event: Event, [low, high]: [number, number], activeThumb: number) => {
    setFilters(prev => (
      {
        ...prev,
        ...{
          rangeFilters: {
            ...prev.rangeFilters,
            [accessKey]: {
              min: low === min.value ? null : low,
              max: high === max.value ? null : high
            },
          }

        }
      }
    ))
  }, [])

  const valueLabelFormat = React.useCallback((label: number) => {
    if (label === min.value) {
      return min.label
    }
    if (label === max.value) {
      return max.label
    }
    return label
  }, [])

  return (
    <div className="filter-slider">
      <Slider
        //getAriaLabel={() => 'Temperature range'}
        value={[
          firstVal ? firstVal : min.value,
          secondVal ? secondVal : max.value
        ]}
        min={min.value}
        max={max.value}
        step={step}
        marks={ticks}
        //getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        // @ts-ignore
        onChange={onChange}
      />
    </div>
  )
}


const BorderRadiusLeft = () => {
  const r = 28;
  return (
    <>
      <svg width={r} height={r} className="top-left-radius">
        <path d={`M 0 0 L ${r} 0 Q 0 0 0 ${r} L 0 0`} style={{ fill: "#0f374f" }} />
      </svg>

      <svg width={r} height={r} className="bottom-left-radius">
        <path d={`M 0 0 Q 0 ${r} ${r} ${r} L 0 ${r} L 0 0`} style={{ fill: "#0f374f" }} />
      </svg>
    </>
  )
}


const BorderRadiusRight = () => {
  const r = 28;
  return (
    <>
      <svg width={r} height={r} className="top-right-radius">
        <path d={`M 0 0 L ${r} 0 L ${r} ${r} Q ${r} 0 0 0`} style={{ fill: "#0f374f" }} />
      </svg>

      <svg width={r} height={r} className="bottom-right-radius">
        <path d={`M ${r} 0 V ${r} H 0 V ${r} Q ${r} ${r} ${r} 0`} style={{ fill: "#0f374f" }} />
      </svg>
    </>
  )
}


export const Filters = () => {
  const { setBuildingLayerFilters, setFilters } = useContext(SetContext);
  const { filtersRef } = useContext(RefContext);
  const { filters } = useContext(FiltersContext);
  const timeoutThrottle = useRef<NodeJS.Timeout | null>(null);


  useEffect(() => {
    // A ref is used so that most recent data is available in timeout
    filtersRef.current = filters;
    if (timeoutThrottle.current !== null) {
      // Execution is pending
      return;
    }

    timeoutThrottle.current = setTimeout(() => {
      const newFilters: Array<any> = [];
      for (const key of Object.keys(filtersRef.current.rangeFilters)) {
        addRangeFilter(filtersRef.current.rangeFilters, key as keyof TBuildingProperties, newFilters);
      }
      for (const key of Object.keys(filtersRef.current.valueFilters)) {
        addValueFilter(filtersRef.current.valueFilters, key as keyof TBuildingProperties, newFilters);
      }
      setBuildingLayerFilters(newFilters);
      timeoutThrottle.current = null;
    }, 1000 / 15) // 15 fps
  }, [filters])


  const setValueFilter = React.useCallback((
    newSet: Set<string | number | null>,
    accessKey: keyof TBuildingProperties
  ) => {
    setFilters(prev => (
      {
        ...prev,
        ...{
          valueFilters: {
            ...prev.valueFilters,
            [accessKey]: newSet
          }
        }
      }
    ))
  }, [filters])


  const valueFilters = React.useMemo(() => (
    <>
      <div style={{ marginTop: 6, marginBottom: 6 }}>
        <p>
          {"Energialuokka (2018)"}
        </p>
        <div style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
          marginLeft: 2,
          marginRight: 2
        }}>
          {(["A", "B", "C", "D", "E", "F", "G", "N/A"] as const).map((value, index: number) => (
            <FilterCheckbox
              key={index}
              filters={filters}
              value={value}
              accessKey={"eluokka"}
              setFilters={setFilters}
              horizontal={true}
              color={COLORS.eluokat[value]}
            />
          ))}
        </div>
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckboxMenu
          title={"Rakennusluokitus"}
          nestedItems={RAKENNUSLUOKITUS_CHECKBOXES}
          uncheckedItems={filters.valueFilters.rluok}
          labelFormatter={(id) => formatRluok(id as TRluok)}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "rluok")
          }}
          showId={true}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckboxMenu
          title={"Lämmönlähde"}
          nestedItems={LAMMONLAHDE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.lammonlahde}
          labelFormatter={(id) => formatLammonlahde(id)}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "lammonlahde")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckboxMenu
          title={"Lämmönjako"}
          nestedItems={LAMMONJAKO_CHECKBOXES}
          uncheckedItems={filters.valueFilters.lammonjako}
          labelFormatter={(id) => formatLammonjako(id)}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "lammonjako")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckboxMenu
          title={"Rakennusaine"}
          nestedItems={RAKENNUSAINE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.rakaine}
          labelFormatter={(id) => formatRakennusaine(id)}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "rakaine")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckboxMenu
          title={"Kattavat laskentaparametrit saatavilla"}
          nestedItems={PROPS_AVAILABLE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.propsAvailable}
          labelFormatter={(id) => {
            switch (id) {
              case 1:
                return "Kyllä"
              case 2:
                return "Ei"

            }
          }}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "propsAvailable")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>
    </>
  ), [filters.valueFilters])


  // NOTE: Filters are scaled automatically.
  // Add a slider or checkbox component for given filter type.
  // They are then added to the filters object which is processed automatically
  return (
    <>
      {valueFilters}

      <div className="filter-container">
        <p>
          {"Valmistumisvuosi"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"vvuosi"}
          min={{ value: 1900, label: `≤${1900}` }}
          max={{ value: 2024, label: `≥${2024}` }}
          tickInterval={20}
          step={1}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"E-luku (kWh/m²/vuosi)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"eluku"}
          min={{ value: 0, label: `0` }}
          max={{ value: 600, label: `≥600` }}
          tickInterval={100}
          step={5}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Kokonaisala (m²)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"korala"}
          min={{ value: 0, label: "0" }}
          max={{ value: 20000, label: `≥${20000}` }}
          tickInterval={4000}
          step={100}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Kerrosten lukumäärä"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"kerrlkm"}
          min={{ value: 0, label: "0" }}
          max={{ value: 16, label: `≥${16}` }}
          tickInterval={2}
          step={1}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Tilavuus (m³)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"tilavuus"}
          min={{ value: 0, label: "0" }}
          max={{ value: 50000, label: `≥${50000}` }}
          tickInterval={10000}
          step={400}
          setFilters={setFilters}
        />
      </div>
    </>
  )
}


// export const PostalAreasFilters = () => {
//   const { setPostalAreasLayerFilters } = useContext(SetContext);
//   const filtersRef = useRef({ rangeFilters: {}, valueFilters: {} });
//   const timeoutThrottle = useRef<NodeJS.Timeout | null>(null);
//   const [filters, setFilters] = useState<TPostalAreaFilters>({ rangeFilters: {}, valueFilters: {} });

//   useEffect(() => {
//     // A ref is used so that most recent data is available in timeout
//     filtersRef.current = filters;
//     if (timeoutThrottle.current !== null) {
//       // Execution is pending
//       return;
//     }

//     timeoutThrottle.current = setTimeout(() => {
//       const newFilters: Array<any> = [];
//       for (const key of Object.keys(filtersRef.current.rangeFilters)) {
//         addRangeFilter(filtersRef.current.rangeFilters, key as keyof TPostalAreaProperties, newFilters);
//       }
//       for (const key of Object.keys(filtersRef.current.valueFilters)) {
//         addValueFilter(filtersRef.current.valueFilters, key as keyof TPostalAreaProperties, newFilters);
//       }
//       setPostalAreasLayerFilters(newFilters);
//       timeoutThrottle.current = null;
//     }, 350)
//   }, [filters])

//   // NOTE: Filters are scaled automatically.
//   // Add a slider or checkbox component for given filter type.
//   // They are then added to the filters object which is processed automatically
//   return (
//     <>
//       <div className="filter-container">
//         <p>
//           {"Asukasluku"}
//         </p>
//         <FilterSlider
//           filters={filters}
//           accessKey={"popCount"}
//           min={{ value: 0, label: "0" }}
//           max={{ value: 10000, label: `≥${10000}` }}
//           tickInterval={2000}
//           step={200}
//           setFilters={setFilters}
//         />
//       </div>

//       <div className="filter-container">
//         <p>
//           {"Alueen pinta-ala (ha)"}
//         </p>
//         <FilterSlider
//           filters={filters}
//           accessKey={"areaSize"}
//           min={{ value: 0, label: "0" }}
//           max={{ value: 20000, label: `≥${20000}` }}
//           tickInterval={5000}
//           step={200}
//           setFilters={setFilters}
//         />
//       </div>
//     </>
//   )
// }