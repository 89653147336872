import { useContext } from "react"
import { ActionsTable } from "./ActionsTable"
import { EnergyCalculatorContext } from "../Misc/Context"






export const ActionsTab = () => {
  const { energyCalcResponse } = useContext(EnergyCalculatorContext);

  if (!energyCalcResponse) return null

  return (
    <>
      <ActionsTable />

      {/* <p>
          {JSON.stringify(energyCalcResponse?.actionsEffect)}
        </p> */}
    </>
  )
}