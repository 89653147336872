import React, { useContext } from "react";
import { DeleteForever, ExpandLess, ExpandMore, ModeEdit, Replay } from "@mui/icons-material"
import { Button, IconButton, ThemeProvider } from "@mui/material"

import { MainContext, RefContext, SetContext } from "../Misc/Context";
import { MUI_THEME_LEFT_PANEL } from "../Misc/muiThemes";
import { Filters } from "./Filters";
import "./LeftPanel.css";



export const LeftPanel = () => {
  const { viewMode, leftPanelCollapsed } = useContext(MainContext);
  const { setSelectedPolygons, setLeftPanelCollapsed, setFilters } = useContext(SetContext);
  const { drawControlRef } = useContext(RefContext);


  const resetFilters = React.useCallback(() => {
    setFilters({ rangeFilters: {}, valueFilters: {} });
  }, [])


  const setPolygonDrawMode = React.useCallback(() => {
    if (!drawControlRef.current) return;
    drawControlRef.current.changeMode("draw_polygon");
  }, [])


  const removePolygons = React.useCallback(() => {
    setSelectedPolygons({});
    if (!drawControlRef.current) return;
    drawControlRef.current.deleteAll();
    drawControlRef.current.changeMode("simple_select");
  }, [])


  return (
    <>
      {/* <BorderRadiusRight />
    <BorderRadiusLeft /> */}
      <div className={leftPanelCollapsed ? "left-panel-collapsed" : "left-panel"}>
        <div className="energiakartta-title-div">
          <p style={{ fontSize: 26 }}>
            {"Energiakartta."}
          </p>
          <p>
            {"Beta"}
          </p>
        </div>


        <div className="left-panel-scrollable-div">
          <ThemeProvider theme={MUI_THEME_LEFT_PANEL}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 12,
              marginBottom: 8
            }}>
              {viewMode === "buildings" ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={setPolygonDrawMode}
                    startIcon={<ModeEdit />}
                  >
                    {"Piirrä uusi alue"}
                  </Button>

                  <Button
                    variant="outlined" // outlined
                    onClick={removePolygons}
                    startIcon={<DeleteForever />}
                  >
                    {"Poista alueet"}
                  </Button>
                </>
              ) : null}

              <Button
                variant="outlined" // outlined
                onClick={resetFilters}
                startIcon={<Replay />}
              >
                {"Nollaa suodattimet"}
              </Button>
            </div>

            <div className="horizontal-divider-wrapper">
              <div className="horizontal-divider" />
            </div>

            <Filters />
          </ThemeProvider>
        </div>
      </div>

      <div className={leftPanelCollapsed ? "collapse-button-collapsed" : "collapse-button"}>
        <IconButton
          onClick={() => setLeftPanelCollapsed(prev => !prev)}
        >
          {leftPanelCollapsed ? (
            <ExpandLess sx={{ color: "#b8b8b8", fontSize: 30, transform: "rotate(90deg)" }} />
          ) : (
            <ExpandMore sx={{ color: "#b8b8b8", fontSize: 30, transform: "rotate(90deg)" }} />
          )}
        </IconButton>
      </div>
    </>
  )
}