import React, { useContext, useState } from "react";
import { visuallyHidden } from '@mui/utils';

import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox
} from "@mui/material"
import { TApi, TEnergySavingActions } from "dippa-shared";
import { EnergyCalculatorContext, SetContext } from "../Misc/Context";
import { COLORS } from "../Misc/consts";



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


type Order = 'asc' | 'desc';


function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type HeadCell = {
  id: string;
  label: string;
  numeric: boolean;
}

const columns: Array<HeadCell> = [
  {
    id: 'id',
    label: 'Toimenpide',
    numeric: false
  },
  {
    id: 'ostoenergiaChange',
    label: 'Vaikutus ostoenergiaan (%)',
    numeric: true
  },
  {
    id: 'elukuChange',
    label: 'Vaikutus E-lukuun (%)',
    numeric: true
  },
  {
    id: 'paybackTime',
    label: 'Takaisinmaksuaika (a)',
    numeric: true
  }
];


type EnhancedTableProps = {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}


const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Select all actions',
            }}
          />
        </TableCell>
        {columns.map((column, index) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={'none'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export const ActionsTable = () => {
  const { energyCalcResponse, selectedActions } = useContext(EnergyCalculatorContext);
  const { setSelectedActions } = useContext(SetContext);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('ostoenergiaChange');
  const rows = energyCalcResponse?.actionsTable ?? [];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelectedActions(newSelected);
      return;
    }
    setSelectedActions([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: TEnergySavingActions) => {
    const selectedIndex = selectedActions.indexOf(id);
    let newSelected: Array<TEnergySavingActions> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedActions, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedActions.slice(1));
    } else if (selectedIndex === selectedActions.length - 1) {
      newSelected = newSelected.concat(selectedActions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedActions.slice(0, selectedIndex),
        selectedActions.slice(selectedIndex + 1),
      );
    }
    setSelectedActions(newSelected);
  };

  const isSelected = (id: TEnergySavingActions) => selectedActions.indexOf(id) !== -1;

  const visibleRows = React.useMemo(() => (
    [...rows].sort(getComparator(order, orderBy))
  ), [order, orderBy, rows]);

  return (
    <Box>
      <Paper sx={{ backgroundColor: COLORS.sitowise.musta }}>
        <TableContainer sx={{ height: 300 }}>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selectedActions.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align={"left"}
                    >
                      {row.id}
                    </TableCell>

                    <TableCell
                      padding="none"
                      align={"right"}
                    >
                      {row.ostoenergiaChange.toFixed(1)}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align={"right"}
                    >
                      {row.elukuChange.toFixed(1)}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align={"right"}
                    >
                      {row.paybackTime.toFixed(1)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}