import type { LayerProps } from 'react-map-gl';

import { COLORS } from '../Misc/consts';
import { TBuildingPointGeojson, TViewMode } from 'dippa-shared';
import { BUILDINGS_3D_ZOOM_BOUNDARY, BUILDINGS_ZOOM_BOUNDARY, MUNICIPALITIES_ZOOM_BOUNDARY } from './Map';
import { interpolateColor } from '../Misc/commonFunctions';


const formatFilter = (filters: Array<any>) => {
  if (!filters.length) return ["all"]; // NOTE: There's a bug in outline layer. Undefined isn't accepted
  return [
    "all",
    //['!', ['has', 'point_count']]
  ].concat(filters)
}


const radiusInterpolator = (multipl: number): any => {
  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    11,
    1.5 * multipl,
    15,
    6 * multipl
  ]
}


export const getBuildingLayer = (
  buildingLayerFilters: Array<any>,
  viewMode: TViewMode
): LayerProps => {
  //console.log(formatFilter(buildingLayerFilters))
  const opacity: any = [
    'interpolate',
    ['linear'],
    ['zoom'],
    BUILDINGS_3D_ZOOM_BOUNDARY,
    viewMode === "buildings" ? 1 : 0,
    BUILDINGS_3D_ZOOM_BOUNDARY + 0.25,
    0
  ];
  return (
    {
      id: 'building-points',
      type: 'circle',
      source: 'geojson-buildings',
      minzoom: BUILDINGS_ZOOM_BOUNDARY - 0.3,
      maxzoom: BUILDINGS_3D_ZOOM_BOUNDARY + 0.3,
      filter: formatFilter(buildingLayerFilters),
      paint: {
        'circle-color': [
          'match', ['get', 'eluokka'],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          COLORS.eluokat['N/A']
        ],
        'circle-radius': radiusInterpolator(1),
        'circle-stroke-width': radiusInterpolator(0.5),
        'circle-stroke-color': "#000000",
        "circle-opacity": opacity,
        "circle-stroke-opacity": opacity
      }
    }
  )
};


export const getBuilding3dLayer = (
  buildingLayerFilters: Array<any>,
  selectedBuilding: TBuildingPointGeojson["features"][0] | null
): Array<LayerProps> => {
  return (
    // {
    //   'id': 'buildings-3d',
    //   'source': 'composite',
    //   'source-layer': 'building',
    //   'filter': ['==', 'extrude', 'true'],
    //   'type': 'fill-extrusion',
    //   'minzoom': 15,
    //   'paint': {
    //     'fill-extrusion-color': '#aaa',

    //     // Use an 'interpolate' expression to
    //     // add a smooth transition effect to
    //     // the buildings as the user zooms in.
    //     'fill-extrusion-height': [
    //       'interpolate',
    //       ['linear'],
    //       ['zoom'],
    //       15,
    //       0,
    //       15.05,
    //       ['get', 'height']
    //     ],
    //     'fill-extrusion-base': [
    //       'interpolate',
    //       ['linear'],
    //       ['zoom'],
    //       15,
    //       0,
    //       15.05,
    //       ['get', 'min_height']
    //     ],
    //     'fill-extrusion-opacity': 0.6
    //   }
    // }

    [
      {
        id: 'buildings-3d-outline',
        type: 'line',
        source: 'geojson-3d-buildings',
        minzoom: BUILDINGS_3D_ZOOM_BOUNDARY - 0.75,
        filter: formatFilter(buildingLayerFilters),
        paint: {
          'line-color': '#ffffff',
          'line-width': 1.5,
          "line-opacity": [
            'interpolate',
            ['linear'],
            ['zoom'],
            BUILDINGS_3D_ZOOM_BOUNDARY,
            0,
            BUILDINGS_3D_ZOOM_BOUNDARY + 0.25,
            1
          ]
        }
      },
      {
        'id': 'buildings-3d',
        'source': 'geojson-3d-buildings',
        'type': 'fill-extrusion',
        'minzoom': BUILDINGS_3D_ZOOM_BOUNDARY - 0.75,
        filter: formatFilter(buildingLayerFilters),
        'paint': {
          'fill-extrusion-color': [
            'match', ['get', 'eluokka'],
            'A', COLORS.eluokat["A"],
            'B', COLORS.eluokat["B"],
            'C', COLORS.eluokat["C"],
            'D', COLORS.eluokat["D"],
            'E', COLORS.eluokat["E"],
            'F', COLORS.eluokat["F"],
            'G', COLORS.eluokat["G"],
            COLORS.eluokat['N/A']
          ],
          //  selectedBuilding ? [
          //   'match', ['get', 'rtunnus'],
          //   selectedBuilding.properties?.rtunnus, "#ffffff",
          //   [
          //     'match', ['get', 'eluokka'],
          //     'A', interpolateColor(COLORS.eluokat["A"], "#ffffff", 0.5),
          //     'B', interpolateColor(COLORS.eluokat["B"], "#ffffff", 0.5),
          //     'C', interpolateColor(COLORS.eluokat["C"], "#ffffff", 0.5),
          //     'D', interpolateColor(COLORS.eluokat["D"], "#ffffff", 0.5),
          //     'E', interpolateColor(COLORS.eluokat["E"], "#ffffff", 0.5),
          //     'F', interpolateColor(COLORS.eluokat["F"], "#ffffff", 0.5),
          //     'G', interpolateColor(COLORS.eluokat["G"], "#ffffff", 0.5),
          //     interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.5)
          //   ]
          // ] : [
          //   'match', ['get', 'eluokka'],
          //   'A', COLORS.eluokat["A"],
          //   'B', COLORS.eluokat["B"],
          //   'C', COLORS.eluokat["C"],
          //   'D', COLORS.eluokat["D"],
          //   'E', COLORS.eluokat["E"],
          //   'F', COLORS.eluokat["F"],
          //   'G', COLORS.eluokat["G"],
          //   COLORS.eluokat['N/A']
          // ],
          'fill-extrusion-height': ["get", "korkeus"],
          'fill-extrusion-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            BUILDINGS_3D_ZOOM_BOUNDARY,
            0,
            BUILDINGS_3D_ZOOM_BOUNDARY + 0.25,
            0.95
          ]
        }
      }
    ]
  )
};


export const getSelectedBuildingLayer = (
  buildingLayerFilters: Array<any>,
  viewMode: TViewMode
): Array<LayerProps> => {
  return ([
    {
      id: 'selected-building-1',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: BUILDINGS_ZOOM_BOUNDARY - 0.2,
      paint: {
        'circle-color': [
          'match', ['get', 'eluokka'],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(4),
        "circle-opacity": viewMode === "buildings" ? 0.2 : 0
      }
    },
    {
      id: 'selected-building-2',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: BUILDINGS_ZOOM_BOUNDARY - 0.2,
      paint: {
        'circle-color': [
          'match', ['get', 'eluokka'],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          interpolateColor(COLORS.eluokat['N/A'], "#ffffff", 0.4)
        ],
        'circle-radius': radiusInterpolator(2.9),
        "circle-opacity": viewMode === "buildings" ? 0.4 : 0
      }
    },
    {
      id: 'selected-building-3',
      type: 'circle',
      source: 'geojson-selected-building',
      minzoom: BUILDINGS_ZOOM_BOUNDARY - 0.2,
      paint: {
        'circle-color': [
          'match', ['get', 'eluokka'],
          'A', COLORS.eluokat["A"],
          'B', COLORS.eluokat["B"],
          'C', COLORS.eluokat["C"],
          'D', COLORS.eluokat["D"],
          'E', COLORS.eluokat["E"],
          'F', COLORS.eluokat["F"],
          'G', COLORS.eluokat["G"],
          COLORS.eluokat['N/A']
        ],
        'circle-radius': radiusInterpolator(1.4),
        'circle-stroke-width': radiusInterpolator(0.5),
        'circle-stroke-color': "#ffffff",
        "circle-opacity": viewMode === "buildings" ? 1 : 0,
        "circle-stroke-opacity": viewMode === "buildings" ? 1 : 0,
      }
    },
  ])
};


export const getPostalAreasLayer = (
  postalAreasLayerFilters: Array<any>,
  viewMode: TViewMode
): Array<LayerProps> => {
  return [
    {
      id: "postal-areas-fill",
      type: "fill",
      source: 'geojson-postal-areas',
      maxzoom: BUILDINGS_ZOOM_BOUNDARY + 0.2,
      filter: formatFilter(postalAreasLayerFilters),
      paint: {
        'fill-color': COLORS.sitowise.niitty,
        'fill-opacity': viewMode === "postalAreas" ? 0.1 : 0
      }
    },
    {
      id: 'postal-areas-outline',
      type: 'line',
      source: 'geojson-postal-areas',
      maxzoom: BUILDINGS_ZOOM_BOUNDARY + 0.2,
      filter: formatFilter(postalAreasLayerFilters),
      paint: {
        'line-color': '#ffffff',
        'line-width': 1.5,
        "line-opacity": viewMode === "postalAreas" ? 0.7 : 0
      }
    }
  ]
}


export const getMunicipalityAreasLayer = (
  municipalityAreasLayerFilters: Array<any>,
  viewMode: TViewMode
): Array<LayerProps> => {
  return [
    {
      id: "municipality-areas-fill",
      type: "fill",
      source: 'geojson-municipality-areas',
      maxzoom: MUNICIPALITIES_ZOOM_BOUNDARY + 1,
      //filter: formatFilter(postalAreasLayerFilters),
      paint: {
        'fill-color': COLORS.sitowise.niitty,
        'fill-opacity': viewMode === "municipalityAreas" ? 0.1 : 0
      }
    },
    {
      id: 'municipality-areas-outline',
      type: 'line',
      source: 'geojson-municipality-areas',
      maxzoom: MUNICIPALITIES_ZOOM_BOUNDARY + 1,
      //filter: formatFilter(postalAreasLayerFilters),
      paint: {
        'line-color': '#ffffff',
        'line-width': 1.5,
        "line-opacity": viewMode === "municipalityAreas" ? 0.7 : 0
      }
    }
  ]
}


export const DRAW_CONTROL_STYLES = [
  // ACTIVE (being drawn)
  // line stroke
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.niitty,
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    "paint": {
      "fill-color": COLORS.sitowise.niitty,
      "fill-outline-color": COLORS.sitowise.niitty,
      "fill-opacity": 0.1
    }
  },
  // polygon mid points
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']],
    'paint': {
      'circle-radius': 3,
      'circle-color': COLORS.sitowise.niitty
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    "id": "gl-draw-polygon-stroke-active",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.niitty,
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
    "paint": {
      "circle-radius": 5,
      "circle-color": "#FFF"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
    "paint": {
      "circle-radius": 3,
      "circle-color": COLORS.sitowise.niitty
    }
  },


  // INACTIVE
  // line stroke
  {
    "id": "gl-draw-line-inactive",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "false"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.kajo,
      "line-width": 2.5
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill-inactive",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    "paint": {
      "fill-color": COLORS.sitowise.kajo,
      "fill-outline-color": COLORS.sitowise.kajo,
      "fill-opacity": 0.1
    }
  },
  // polygon outline
  {
    "id": "gl-draw-polygon-stroke-inactive",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": COLORS.sitowise.kajo,
      "line-width": 2.5
    }
  }
]