import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider } from '@emotion/react';
import { MUI_THEME_LOGIN } from './muiThemes';
import { Alert, Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import { COLORS, SERVER_URL } from './consts';
import { axiosFetch, axiosPost } from './commonFunctions';
import "./AdminDashboard.css";


export const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [selectedRole, setSelectedRole] = useState('user');
  const [error, setError] = useState("");

  const deleteUser = async (userId: string) => {
    try {
      setError(``);
      const token = localStorage.getItem('token');
      await axios.delete(`${SERVER_URL}/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchUsers();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error deleting user: ${JSON.stringify(e.response)}`);
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/admin/users`)
      setUsers(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error fetching users: ${JSON.stringify(e.response)}`);
      }
    }
  };

  // Fetch all users
  useEffect(() => {
    fetchUsers();
  }, []);

  // Register a new user
  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      setError(``);
      await axiosPost(`${SERVER_URL}/admin/users`, {
        username: data.get("username"),
        password: data.get("password"),
        role: selectedRole
      })
      fetchUsers();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError(`Error registering user: ${JSON.stringify(e.response)}`);
      }
    }
  };

  const handleClose = () => {
    setUserIdToDelete("");
  };

  const handleConfirm = () => {
    deleteUser(userIdToDelete)
    setUserIdToDelete("");
  };

  if (users.length === 0) return null;

  return (
    <div className='admin-dashboard'>
      <h2>All Users</h2>
      <ul>
        {users.map((user: { _id: string, username: string, role: string, createdAt: number | Date }) => (
          <li
            key={user._id}>
            {`${user.username}, ${user.role}, luotu: ${user.createdAt ? new Date(user.createdAt).toISOString() : ""}`}

            <IconButton
              onClick={() => setUserIdToDelete(user._id)}
            >
              <DeleteForever sx={{ color: COLORS.sitowise.graniitti, fontSize: 30, padding: 0 }} />
              {/* {title} */}
            </IconButton>
          </li>
        ))}
      </ul>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <h2>Register New User</h2>

      <ThemeProvider theme={MUI_THEME_LOGIN}>
        <div className="mui-login">
          {/* <div style={{ marginBottom: 95, borderWidth: 3, borderStyle: "solid", borderRadius: 14, padding: 14, paddingTop: 7, color: "#ffffff" }}>
            <p style={{ fontSize: 35 }}>
              {"Energiakartta."}
            </p>
            <p style={{ fontSize: 18 }}>
              {"Beta"}
            </p>
          </div> */}

          {/* @ts-ignore */}
          <Box component="form" onSubmit={handleRegister} noValidate>
            <TextField
              required
              fullWidth
              id="username"
              label="Käyttäjätunnus"
              name="username"
              autoComplete="username"
              autoFocus
              sx={{ mb: 2.5, mt: 2.5 }}
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Salasana"
              type="password"
              id="password"
              autoComplete="password"
              sx={{ mb: 2.5 }}
            />
            <RadioGroup value={selectedRole} onChange={(e) => { setSelectedRole(e.target.value) }} sx={{ flexDirection: "row", justifyContent: "space-around" }}>
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel value="demo" control={<Radio />} label="Demo" />
              <FormControlLabel value="admin" control={<Radio />} label="Admin" />
            </RadioGroup>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mb: 2.5, mt: 2.5 }}
            >
              Rekisteröi
            </Button>
          </Box>

          {error && <Alert severity="error" sx={{
            borderRadius: 3,
            backgroundColor: "#00000000",
            color: COLORS.sitowise.graniitti,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: COLORS.sitowise.graniitti,
            '& .MuiAlert-icon': { color: COLORS.sitowise.graniitti }
          }}>{error}</Alert>}
        </div>

        <Dialog
          open={!!userIdToDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
          <div>
            <p>
              Are you sure you want to perform this action?
            </p>
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};