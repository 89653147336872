export const DROPDOWN_VALS = [{
  title: "Rakennusrekisterin alkuperäiset arvot",
  accessKey: "originalProps",
  vals: [
    "lammonlahde",
    "lammonjako",
    "kerala",
    "pnumero",
  ]
},
{
  title: "Yhdistetyt arvot",
  accessKey: "mapProps",
  vals: [
    "vvuosi",
    "rluok",
    "lammonlahde",
    "lammonjako",
    "kerrlkm",
    "korala",
    "rakaine",
    "tilavuus",
    "eluku"
  ]
},
{
  title: "Johdetut arvot",
  accessKey: "derivedProps",
  vals: [
    "ikkunatPercentage",
    "ulkoovetPercentage",
    "arkTyyp",
    "painovoimIlmanvaihto"
  ]
},
{
  title: "Laskentaparametrit",
  accessKey: "calcProps",
  vals: [
    "k_ihmiset",
    "k_valaistus",
    "Q_lkv_omin",
    "q_i_omin",
    "T_s",
    "P_kuluttajalaitteet",
    "P_ihmiset",
    "t_d",
    "t_w",
    "A_ulkoseinä",
    "A_yläpohja",
    "A_alapohja",
    "A_ikkunat",
    "A_ulkoovet",
    "A_netto",
    "kayttotarkoitusluokka",
    "U_ulkoseinä",
    "U_yläpohja",
    "U_alapohja",
    "U_ikkunat",
    "U_ovet",
    "q50",
    "η_lto",
    "SFP",
    "φ_lkv_kierto",
    "η_lammitys_tilat",
    "e_tilat",
    "e_lkv",
    "x_vuotoilma",
    "u_kylmäsillat",
    "P_valaistus",
    "l_lkv_kierto",
    "C_rak_omin",
    "η_lkv_siirto",
    "η_tuotto",
    "e_tuotto"
  ]
},
{
  title: "Energiatodistuksen arvot",
  accessKey: "certProps",
  vals: [
    "Perustiedot / Kayttotarkoitus",
    "Perustiedot / Valmistumisvuosi",
    "Lahtotiedot / Lammitetty-nettoala",
    "Lahtotiedot / Lammitys / Lammitysmuoto-1 / Id",
    "Lahtotiedot / Lammitys / Lammitysmuoto-2 / Id",
    "Lahtotiedot / Lammitys / Lammonjako / Id",
    "Tulokset / Kaytettavat-energiamuodot / Kaukolampo",
    "Tulokset / Kaytettavat-energiamuodot / Sahko",
    "Tulokset / Kaytettavat-energiamuodot / Uusiutuva-polttoaine",
    "Tulokset / Kaytettavat-energiamuodot / Fossiilinen-polttoaine",
    "Tulokset / Kaytettavat-energiamuodot / Kaukojaahdytys",
    "Tulokset / Kaytettavat-energiamuodot / Kaukolampo-nettoala",
    "Tulokset / Kaytettavat-energiamuodot / Sahko-nettoala",
    "Tulokset / Kaytettavat-energiamuodot / Uusiutuva-polttoaine-nettoala",
    "Tulokset / Kaytettavat-energiamuodot / Fossiilinen-polttoaine-nettoala",
    "Tulokset / Kaytettavat-energiamuodot / Kaukojaahdytys-nettoala",
    "Lahtotiedot / Lammitys / Tilat-ja-iv / Jaon-hyotysuhde",
    "Lahtotiedot / Lammitys / Tilat-ja-iv / Tuoton-hyotysuhde",
    "Lahtotiedot / Rakennusvaippa / Ilmanvuotoluku",
    "Lahtotiedot / Ilmanvaihto / Lto-vuosihyotysuhde",
    "Lahtotiedot / Ilmanvaihto / Ivjarjestelma / Sfp",
    "Lahtotiedot / Lammitys / Tilat-ja-iv / Lampokerroin",
    "Lahtotiedot / Lammitys / Lammin-kayttovesi / Lampokerroin"
  ]
}
] as const