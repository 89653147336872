import React, { createContext, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { TApi, TBuildingFilters, TBuildingPointGeojson, TCalcProps, TEnergySavingActions, TViewMode } from "dippa-shared";


export type TSelectedPolygons = {
  geometry: { coordinates: Array<Array<[number, number]>>, type: 'Polygon' } | { coordinates: Array<Array<Array<[number, number]>>>, type: 'MultiPolygon' }
  id: string
  properties: object
  type: "Feature"
}

export type TSetState<T> = React.Dispatch<React.SetStateAction<T>>
type TMainContext = {
  selectedPolygons: { [key: string]: TSelectedPolygons },
  buildingLayerFilters: Array<any>,
  postalAreasLayerFilters: Array<any>,
  viewMode: TViewMode,
  leftPanelCollapsed: boolean
}
type TSelectedBuildingContext = {
  selectedBuilding: TBuildingPointGeojson["features"][0] | null,
  //selected3dBuilding: TBuildingPolygonGeojson["features"][0] | null
}
type TLoadingStatusContext = {
  loadingStatus: "" | "loading" | string,
}
type TEnergyCalculatorContext = {
  energyCalculatorOpen: boolean,
  energyCalcResponse: TApi["energy-calculator.json"]["response"] | undefined,
  selectedActions: Array<TEnergySavingActions>,
  energyCalcProps: TCalcProps | undefined
}
type TFiltersContext = {
  filters: TBuildingFilters,
}
type TRefContext = {
  filtersRef: React.MutableRefObject<TBuildingFilters>,
  drawControlRef: React.RefObject<MapboxDraw | null>
}
type TSetContext = {
  setFilters: TSetState<TBuildingFilters>,
  setEnergyCalculatorOpen: TSetState<boolean>,
  setLoadingStatus: TSetState<"" | "loading" | string>,
  setEnergyCalcResponse: TSetState<TApi["energy-calculator.json"]["response"] | undefined>,
  setSelectedActions: TSetState<Array<TEnergySavingActions>>,
  setEnergyCalcProps: TSetState<TCalcProps | undefined>,
  setSelectedBuilding: TSetState<TBuildingPointGeojson["features"][0] | null>,
  //setSelected3dBuilding: TSetState<TBuildingPolygonGeojson["features"][0] | null>,
  setBuildingLayerFilters: TSetState<Array<any>>,
  setPostalAreasLayerFilters: TSetState<Array<any>>,
  setSelectedPolygons: TSetState<{ [key: string]: TSelectedPolygons }>,
  setViewMode: TSetState<TViewMode>,
  setLeftPanelCollapsed: TSetState<boolean>
}

export const MainContext = createContext<TMainContext>({} as TMainContext);
export const SelectedBuildingContext = createContext<TSelectedBuildingContext>({} as TSelectedBuildingContext);
export const LoadingStatusContext = createContext<TLoadingStatusContext>({} as TLoadingStatusContext);
export const EnergyCalculatorContext = createContext<TEnergyCalculatorContext>({} as TEnergyCalculatorContext);
export const FiltersContext = createContext<TFiltersContext>({} as TFiltersContext);
export const RefContext = createContext<TRefContext>({} as TRefContext);
export const SetContext = createContext<TSetContext>({} as TSetContext);

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<TBuildingPointGeojson["features"][0] | null>(null);
  //const [selected3dBuilding, setSelected3dBuilding] = useState<TBuildingPolygonGeojson["features"][0] | null>(null);
  const [energyCalcResponse, setEnergyCalcResponse] = useState<TApi["energy-calculator.json"]["response"] | undefined>();
  const [selectedActions, setSelectedActions] = useState<TEnergySavingActions[]>([]);
  const [energyCalcProps, setEnergyCalcProps] = useState<TCalcProps | undefined>()
  const [energyCalculatorOpen, setEnergyCalculatorOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<TBuildingFilters>({ rangeFilters: {}, valueFilters: {} });
  const [loadingStatus, setLoadingStatus] = useState<"" | "loading" | string>("loading");
  const [selectedPolygons, setSelectedPolygons] = useState<{ [key: string]: TSelectedPolygons }>({});
  const [viewMode, setViewMode] = useState<TViewMode>("buildings");
  const [buildingLayerFilters, setBuildingLayerFilters] = useState<Array<any>>([]);
  const [postalAreasLayerFilters, setPostalAreasLayerFilters] = useState<Array<any>>([])
  const drawControlRef = useRef<MapboxDraw | null>(null)
  const filtersRef = useRef<TBuildingFilters>({
    rangeFilters: {},
    valueFilters: {}
  });

  const mainContext: TMainContext = React.useMemo(() => ({
    selectedPolygons,
    buildingLayerFilters,
    postalAreasLayerFilters,
    viewMode,
    leftPanelCollapsed
  }), [
    selectedPolygons,
    buildingLayerFilters,
    postalAreasLayerFilters,
    viewMode,
    leftPanelCollapsed
  ]);

  const selectedBuildingContext: TSelectedBuildingContext = React.useMemo(() => ({
    selectedBuilding,
    //selected3dBuilding
  }), [
    selectedBuilding,
    //selected3dBuilding
  ])

  const loadingStatusContext: TLoadingStatusContext = React.useMemo(() => ({
    loadingStatus
  }), [loadingStatus])

  const energyCalculatorContext: TEnergyCalculatorContext = React.useMemo(() => ({
    energyCalculatorOpen,
    energyCalcResponse,
    selectedActions,
    energyCalcProps
  }), [
    energyCalculatorOpen,
    energyCalcResponse,
    selectedActions,
    energyCalcProps
  ])

  const filtersContext: TFiltersContext = React.useMemo(() => ({
    filters
  }), [filters])

  const refContext: TRefContext = React.useMemo(() => ({
    filtersRef,
    drawControlRef
  }), [])

  const setContext: TSetContext = React.useMemo(() => ({
    setFilters,
    setEnergyCalculatorOpen,
    setLoadingStatus,
    setEnergyCalcResponse,
    setSelectedActions,
    setEnergyCalcProps,
    setSelectedBuilding,
    //setSelected3dBuilding,
    setBuildingLayerFilters,
    setPostalAreasLayerFilters,
    setSelectedPolygons,
    setViewMode,
    setLeftPanelCollapsed
  }), []);

  return (
    <RefContext.Provider value={refContext}>
      <SelectedBuildingContext.Provider value={selectedBuildingContext}>
        <LoadingStatusContext.Provider value={loadingStatusContext}>
          <EnergyCalculatorContext.Provider value={energyCalculatorContext}>
            <FiltersContext.Provider value={filtersContext}>
              <SetContext.Provider value={setContext}>
                <MainContext.Provider value={mainContext}>
                  {children}
                </MainContext.Provider>
              </SetContext.Provider>
            </FiltersContext.Provider>
          </EnergyCalculatorContext.Provider>
        </LoadingStatusContext.Provider>
      </SelectedBuildingContext.Provider>
    </RefContext.Provider>
  )
}