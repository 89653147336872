import { getRegionKeysInsideBounds, TBuildingPolygonGeojson, TBuildingPointGeojson, TBuildingProperties } from "dippa-shared";
import { axiosFetch } from "../Misc/commonFunctions";
import { SERVER_URL } from "../Misc/consts";


const fetchRegion = async (bbox: string) => {
  const res = await axiosFetch(`${SERVER_URL}/buildings.json?bbox=${bbox}`)
  if (res.status === 205) {
    // Empty area
    return { pointFeatures: [], polygonFeatures: [] };
  }
  const json = res.data;
  const pointFeatures: TBuildingPointGeojson["features"] = []
  const polygonFeatures: TBuildingPolygonGeojson["features"] = [];
  const keys = Object.keys(json)
  const keysFiltered = keys.filter(v => (v !== "geometry" && v !== "lat" && v !== "lon"))
  const len = json[keys[0]].length
  for (let i = 0; i < len; i++) {
    const properties = keysFiltered.reduce((prev, key) => {
      // @ts-ignore
      prev[key] = json[key][i];
      return prev
    }, {} as TBuildingProperties);
    pointFeatures.push({
      "type": "Feature",
      "properties": properties,
      "geometry": { type: "Point", coordinates: [json.lon[i], json.lat[i]] }
    })
    if (json.geometry[i]) {
      polygonFeatures.push({
        "type": "Feature",
        "properties": properties,
        "geometry": json.geometry[i]
      })
    }
  }
  return { pointFeatures, polygonFeatures };
}


const getRegion = async (
  bbox: string,
  gjsonPoints: TBuildingPointGeojson,
  gjsonPolygons: TBuildingPolygonGeojson,
  loadedRegions: React.MutableRefObject<{ [key: string]: { pointFeatures: TBuildingPointGeojson["features"], polygonFeatures: TBuildingPolygonGeojson["features"] } }>
) => {
  if (loadedRegions.current[bbox]?.pointFeatures && loadedRegions.current[bbox]?.polygonFeatures) {
    gjsonPoints.features.push(...loadedRegions.current[bbox].pointFeatures);
    gjsonPolygons.features.push(...loadedRegions.current[bbox].polygonFeatures);
    return;
  }
  const { pointFeatures, polygonFeatures } = await fetchRegion(bbox);
  loadedRegions.current[bbox] = { pointFeatures, polygonFeatures };
  gjsonPoints.features.push(...pointFeatures);
  gjsonPolygons.features.push(...polygonFeatures)
}


const limitBounds = (
  minLat: number,
  minLon: number,
  maxLat: number,
  maxLon: number
) => {
  while (true) {
    const size = Math.round((maxLon - minLon) * 10) * Math.round((maxLat - minLat) * 20)
    if (size < 17) {
      break;
    }

    maxLon = Math.round((maxLon - 0.1) * 10) / 10;
    maxLat = Math.round((maxLat - 0.05) * 20) / 20;
    //maxLat = Math.round((maxLat - 0.05) * 20) / 20;

    if (maxLon - minLon > 0.21) {
      minLon = Math.round((minLon + 0.1) * 10) / 10;
    }
    // if (maxLat - minLat > 0.11) {
    //   minLat = Math.round((minLat + 0.05) * 20) / 20;
    // }
  }

  return { minLat, minLon, maxLat, maxLon }
}


export const fetchBuildings = async ({
  minLat,
  minLon,
  maxLat,
  maxLon,
  loadedRegions
}: {
  minLat: number,
  minLon: number,
  maxLat: number,
  maxLon: number,
  loadedRegions: React.MutableRefObject<{ [key: string]: { pointFeatures: TBuildingPointGeojson["features"], polygonFeatures: TBuildingPolygonGeojson["features"] } }>
}) => {
  const gjsonPoints: TBuildingPointGeojson = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "features": []
  };
  const gjsonPolygons: TBuildingPolygonGeojson = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "features": []
  };
  const promises = [];
  const bboxes = getRegionKeysInsideBounds(limitBounds(minLat, minLon, maxLat, maxLon));
  for (const bbox of Object.keys(loadedRegions.current)) {
    // Regions that are not going to be displayed anymore are removed
    // TODO: Poista viiveellä siten, että vanhoja alueita jää hetkeksi muistiin varalta
    if (!bboxes.includes(bbox)) {
      delete loadedRegions.current[bbox];
    }
  }

  for (const bbox of bboxes) {
    promises.push(getRegion(bbox, gjsonPoints, gjsonPolygons, loadedRegions))
  }
  await Promise.all(promises)

  return { gjsonPoints, gjsonPolygons };
}





// import { getRegionKeysInsideBounds, TBuildingPointGeojson } from "dippa-shared";
// import { MutableRefObject } from "react";


// export const fetchBuildings = async (
//   {
//     minLat,
//     minLon,
//     maxLat,
//     maxLon,
//     loadedRegions,
//     worker
//   }: {
//     minLat: number,
//     minLon: number,
//     maxLat: number,
//     maxLon: number,
//     loadedRegions: React.MutableRefObject<{ [key: string]: any }>,
//     worker: MutableRefObject<Worker | null>
//   }
// ) => {
// const gjsonPoints: TBuildingPointGeojson = {
//   "type": "FeatureCollection",
//   "crs": {
//     "type": "name",
//     "properties": {
//       "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//     }
//   },
//   "features": []
// };
// const gjsonPolygons: TBuildingPolygonGeojson = {
//   "type": "FeatureCollection",
//   "crs": {
//     "type": "name",
//     "properties": {
//       "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//     }
//   },
//   "features": []
// };
//   const bboxes = getRegionKeysInsideBounds({ minLon, minLat, maxLon, maxLat });
//   //bboxes.splice(0, 4);
//   for (const bbox of Object.keys(loadedRegions.current)) {
//     // Regions that are not going to be displayed anymore are removed
//     // TODO: Poista viiveellä siten, että vanhoja alueita jää hetkeksi muistiin varalta
//     if (!bboxes.includes(bbox)) {
//       delete loadedRegions.current[bbox];
//     }
//   }

//   if (!worker.current) {
//     worker.current = new Worker(new URL('./worker.ts', import.meta.url));
//   }

//   let taskCount = 0;
//   for (const bbox of bboxes) {
//     if (loadedRegions.current[bbox]) {
//       gjsonPoints.features.push(...loadedRegions.current[bbox].features);
//       gjsonPolygons.features.push(...loadedRegions.current[bbox].polygonFeatures);
//       continue;
//     }
//     worker.current.postMessage({ bbox, token: localStorage.getItem('token') });
//     taskCount++;
//   }

//   if (taskCount) {
//     await new Promise<void>((resolve, reject) => {
//       if (!worker.current) {
//         reject();
//         return
//       }
//       worker.current.onmessage = (event: MessageEvent<{ data?: any, bbox: string, error?: any }>) => {
//         const { data, bbox, error } = event.data;
//         if (error) {
//           reject(error?.message);
//           return
//         }
//         const { features, polygonFeatures } = data;
//         // TODO: Features are sometimes undefined?
//         if (features && polygonFeatures) {
//           loadedRegions.current[bbox] = { features, polygonFeatures };
//           gjsonPoints.features.push(...features);
//           gjsonPolygons.features.push(...polygonFeatures);
//           taskCount--;
//           if (taskCount === 0) {
//             resolve();
//           }
//         }

//       };
//       worker.current.onerror = (error) => {
//         reject(error?.message);
//       };
//     })
//   }

//   return { gjsonPoints, gjsonPolygons };
// }