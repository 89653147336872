import { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './App.css';

// const Login = lazy(() => import("./Misc/Login"));
// const Main = lazy(() => import('./Main'));
import Login from "./Misc/Login"
import { ContextProvider } from './Misc/Context';
import { Map } from './Map/Map';
import { AreaInfo } from './AreaInfo/AreaInfo';
import { BuildingInfo } from './BuildingInfo/BuildingInfo';
import { LoadingStatus } from './Misc/LoadingStatus';
import { EnergyCalculator } from './EnergyCalculator/EnergyCalculator';
import { LeftPanel } from './LeftPanel/LeftPanel';
import { setAxiosInterceptor } from './Misc/interceptor';
import { AdminDashboard } from './Misc/AdminDashboard';



const App = () => {
  const [mapboxToken, setMapboxToken] = useState("");
  const [tokensChecked, setTokensChecked] = useState(false);


  const isTokenExpired = (token: string) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return !decoded.exp || decoded.exp < currentTime;
  };


  const checkTokens = () => {
    const jwtToken = localStorage.getItem('token');
    const mapboxToken = localStorage.getItem('mapboxToken');
    if (!jwtToken || !mapboxToken) {
      localStorage.removeItem('token');
      localStorage.removeItem('mapboxToken');
      return
    }

    if (isTokenExpired(jwtToken)) {
      localStorage.removeItem('token');
      localStorage.removeItem('mapboxToken');
      return
    }

    setMapboxToken(mapboxToken);
  }


  useEffect(() => {
    setAxiosInterceptor(setMapboxToken);
    checkTokens();
    setTokensChecked(true)
  }, [])


  if (!tokensChecked) {
    return (<div className='App' />);
  }


  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={
            mapboxToken ? (
              <Navigate to="/map" replace />
            ) : (
              // <Suspense fallback={<>...</>}>
              <Login setMapboxToken={setMapboxToken} />
              // </Suspense> 
            )
          } />
          <Route path="/admin" element={
            mapboxToken ? (
              <AdminDashboard />
            ) : (
              <Navigate to="/login" replace />
            )
          } />

          <Route path="/map" element={
            mapboxToken ? (
              // <Suspense fallback={<>...</>}>
              <ContextProvider>
                <LeftPanel />
                <Map mapboxToken={mapboxToken} />
                <AreaInfo />
                <BuildingInfo />
                <EnergyCalculator />
                <LoadingStatus />
              </ContextProvider>
              // </Suspense>
            ) : (
              <Navigate to="/login" replace />
            )
          } />
          < Route path="*" element={< Navigate to="/login" replace />} />
        </Routes >
      </BrowserRouter>
    </div>
  );
}


export default App;
